import type { BaseParamsEvent, EventParamsOmitBase } from '~/types/event-tracking'

export function trackEvent<T extends BaseParamsEvent>(params: EventParamsOmitBase<T>) {
  const gtm = useGtm()
  const route = useRoute()
  const { locale } = useLocale()
  gtm?.trackEvent({
    page_category: route.meta.pageCategory,
    campaign_id: route.query.campaign_id,
    campaign_name: route.query.campaign_name,
    locale: locale.value,
    aid: route.query.aid,
    sid: route.query.sid,
    ouid: route.query.ouid,
    ...params,
  })
}
