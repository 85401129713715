/**
 * 自动生成的代码，请勿修改
 */
import { doRequest } from './utils';
import type { ResponseType } from './utils';
import type {
  BaseRequestDTO,
  ResponseListSupplierSearchDTO,
  RecommendCategoryRequest,
  ResponseRecommendWrapDTO,
  GetConfigCityRequest,
  ResponseChannelCityDTO,
  ResponseListSearchLabelDTO,
  QrySubCategoryRequest,
  IntelligentRecommendRequestDTO,
  ResponseListIntelligentRecommendResponseDTO,
  CombineSearchRequest,
  ResponseBasePageResponseDTOSearchDTO,
  VisitHistoryRequest,
  ResponseListSearchDTO,
  ResponseSearchRegionForRankResponse,
} from '../types';

const baseUrl = '';

class HomePageControllerService {
  /**
   * @summary 获取参与搜索的所有品牌信息
   * @fullUrl /homePage/getAllSearchBrand
   */
  public homePageGetAllSearchBrandPost(
    body: BaseRequestDTO,
  ): ResponseType<ResponseListSupplierSearchDTO> {
    const requestUrl = baseUrl + '/homePage/getAllSearchBrand';
    const res = doRequest<ResponseListSupplierSearchDTO>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 推荐瀑布流
   * @fullUrl /homePage/recommendCategory
   */
  public homePageRecommendCategoryPost(
    body: RecommendCategoryRequest,
  ): ResponseType<ResponseRecommendWrapDTO> {
    const requestUrl = baseUrl + '/homePage/recommendCategory';
    const res = doRequest<ResponseRecommendWrapDTO>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 进入各个品类默认的城市
   * @fullUrl /homePage/getConfigCity
   */
  public homePageGetConfigCityPost(
    body: GetConfigCityRequest,
  ): ResponseType<ResponseChannelCityDTO> {
    const requestUrl = baseUrl + '/homePage/getConfigCity';
    const res = doRequest<ResponseChannelCityDTO>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 查询h5 金刚位分类
   * @fullUrl /homePage/qryCategory
   */
  public homePageQryCategoryPost(
    body: BaseRequestDTO,
  ): ResponseType<ResponseListSearchLabelDTO> {
    const requestUrl = baseUrl + '/homePage/qryCategory';
    const res = doRequest<ResponseListSearchLabelDTO>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 搜索条件的边框拼配获取
   * @fullUrl /homePage/qrySubCategory
   */
  public homePageQrySubCategoryPost(
    body: QrySubCategoryRequest,
  ): ResponseType<ResponseListSearchLabelDTO> {
    const requestUrl = baseUrl + '/homePage/qrySubCategory';
    const res = doRequest<ResponseListSearchLabelDTO>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary intelligent recommend
   * @fullUrl /homePage/intelligentRecommend
   */
  public homePageIntelligentRecommendPost(
    body: IntelligentRecommendRequestDTO,
  ): ResponseType<ResponseListIntelligentRecommendResponseDTO> {
    const requestUrl = baseUrl + '/homePage/intelligentRecommend';
    const res = doRequest<ResponseListIntelligentRecommendResponseDTO>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary combine search
   * @fullUrl /homePage/combineSearch
   */
  public homePageCombineSearchPost(
    body: CombineSearchRequest,
  ): ResponseType<ResponseBasePageResponseDTOSearchDTO> {
    const requestUrl = baseUrl + '/homePage/combineSearch';
    const res = doRequest<ResponseBasePageResponseDTOSearchDTO>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary visitHistory
   * @fullUrl /homePage/visitHistory
   */
  public homePageVisitHistoryPost(
    body: VisitHistoryRequest,
  ): ResponseType<ResponseListSearchDTO> {
    const requestUrl = baseUrl + '/homePage/visitHistory';
    const res = doRequest<ResponseListSearchDTO>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 获取搜索热门城市
   * @fullUrl /homePage/getSearchTopCity
   */
  public homePageGetSearchTopCityPost(
    body: CombineSearchRequest,
  ): ResponseType<ResponseSearchRegionForRankResponse> {
    const requestUrl = baseUrl + '/homePage/getSearchTopCity';
    const res = doRequest<ResponseSearchRegionForRankResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
}

export const homePageController = new HomePageControllerService();
