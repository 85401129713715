import { useStorage } from '@vueuse/core'
import {
  attrController,
  attrRankController,
  homePageController,
} from '~/apis/back/services'
import type {
  CombineMinPriceRecordDTO,
  PriceRangeConfigDto,
  RegionSearchResponse,
  SearchDTO,
  SearchLabelDTO,
  SupplierSearchDTO,
} from '~/apis/back/types'
import {
  ENUM_CARD_FROM,
  ENUM_EventType,
  ENUM_PageName,
  E_RECOMMEND_CATEGORY,
} from '~/enums'
import type {
  ProductDtoType,
  RecClicksEvent,
  RecentlyViewedClicksEvent,
  SearchEvent,
  SelectItemClickPosition,
  SelectItemEvent,
  SortApplyEvent,
  TabSwitchEvent,
  ViewItemListEvent,
} from '~/types/event-tracking'

export enum ENUM_SORT_TYPE {
  RECOMMEND = 1,
  PRICE_ASC = 3,
  TOP_RATE = 4,
}

export const defaultFilter = {
  priceMin: 0,
  priceMax: 1000,
  subCategoryIds: undefined as number[] | undefined,
  cityId: undefined as string | undefined,
  cityName: '',
  countryCode: '',
  brandIdList: undefined as number[] | undefined,
}

interface IRecentViewd {
  azgoId: string
  category: E_RECOMMEND_CATEGORY
}

type SearchDtoFormat = SearchDTO & { listPosition?: number }

export function useSearchResult() {
  const { t } = useI18n()
  const isMobile = useMobileByScreen()
  const userInfoStore = useUserStore()
  const { locale } = useLocale()
  const dayjs = useDayjs()
  const citySearchHistory = useStorage<Array<RegionSearchResponse | string>>('AZGO_SEARCH_CITYS_HISTORY', [])

  const recentViewdCookie = useCookie<IRecentViewd[]>('recentViewd')

  const searchState = useState('searchState', () => ({
    showFilterPopup: false,
    showSortByPopup: false,
    loading: false,
    finished: false,
    curPage: 1,
    totalCount: 0,
    jumpLoading: false,
    jumpBrandUrl: '',
    resultList: [] as Array<SearchDtoFormat>,
    categories: [] as Array<SearchLabelDTO>,
    brandList: [] as Array<SupplierSearchDTO>,
    subCategories: [] as Array<SearchLabelDTO>,
    sortType: ENUM_SORT_TYPE.RECOMMEND,
    filter: { ...defaultFilter },
    priceRanges: [] as Array<PriceRangeConfigDto>,
    recentViewedList: [] as Array<SearchDTO>,
  }))

  const {
    showFilterPopup,
    showSortByPopup,
    loading,
    finished,
    curPage,
    jumpLoading,
    jumpBrandUrl,
    resultList,
    categories,
    subCategories,
    brandList,
    filter,
    sortType,
    totalCount,
    priceRanges,
    recentViewedList,
  } = toRefs(searchState.value)

  const categoriesWithAll = computed(() => {
    return [{ labelId: 0, labelName: t('key.home.recommend.tab.all') }, ...categories.value]
  })

  const priceRange = computed(() => {
    return priceRanges.value.find(item => item.currency === userInfoStore.userInfo.currency)
  })

  const route = useRoute()

  const currentTab = computed(() => {
    return Number(route.query.labelId) || 0
  })

  const sortTypeOptions = computed(() => {
    const baseOptions = [
      { label: t('key.search.result.sort.recommend'), value: ENUM_SORT_TYPE.RECOMMEND },
      { label: t('key.search.result.sort.priceasc'), value: ENUM_SORT_TYPE.PRICE_ASC },
    ]
    // 选中景点时，显示按评分排序
    if (currentTab.value === 464) {
      baseOptions.push({ label: t('key.search.result.sort.toprate'), value: ENUM_SORT_TYPE.TOP_RATE })
    }
    return baseOptions
  })

  async function querySearchResult() {
    searchState.value.loading = true
    const pageSize = 20
    const res = await homePageController.homePageCombineSearchPost({
      pageNum: curPage.value,
      pageSize,
      cityId: filter.value.cityId,
      keyWord: route.query.keyword?.toString() ?? '',
      currency: userInfoStore.userInfo.currency,
      // categoryId: currentTab.value ? currentTab.value : undefined,
      // subCategoryIdList: filter.value.subCategoryIds,
      priceMin: filter.value.priceMin,
      priceMax: filter.value.priceMax,
      brandIdList: filter.value.brandIdList?.length ? filter.value.brandIdList : undefined,
      sortType: sortType.value,
    })
    if (curPage.value === 1) {
      searchState.value.resultList = []
    }
    const data = res.data.value?.data?.data?.map((item, index) => ({
      ...item,
      listPosition: (curPage.value - 1) * pageSize + index + 1,
    })) || []
    searchState.value.resultList.push(...data)
    searchState.value.totalCount = res.data.value?.data?.pageInfo?.total ?? 0
    searchState.value.loading = false
    if (import.meta.client) {
      trackEvent<ViewItemListEvent>({
        event: ENUM_EventType.ViewItemList,
        item_number: searchState.value.totalCount,
        prd_type_id: currentTab.value,
        city: filter.value.cityName,
        city_id: filter.value.cityId,
        country_code: filter.value.countryCode,
        page_category: ENUM_PageName.SearchResultPage,
        loading_type: curPage.value === 1 ? 'first' : 'scroll',
      })
    }
    if (curPage.value * pageSize >= searchState.value.totalCount) {
      searchState.value.finished = true
    }
  }

  function scrollQuerySearchResult() {
    if (!finished.value && !loading.value && resultList.value.length > 0) {
      searchState.value.curPage++
      querySearchResult()
    }
  }

  async function queryCategories() {
    const res = await homePageController.homePageQryCategoryPost({})
    if (res.data.value?.data?.length) {
      searchState.value.categories = res.data.value.data
    }
    else {
      searchState.value.categories = []
    }
  }

  function changeTab(name: number) {
    trackEvent<TabSwitchEvent>({
      event: ENUM_EventType.TabSwitch,
      tab_id: name.toString(),
      tab_name: categoriesWithAll.value.find(item => item.labelId === name)?.labelName ?? '',
      previous_tab_id: currentTab.value.toString(),
      previous_tab_name: categoriesWithAll.value.find(item => item.labelId === currentTab.value)?.labelName ?? '',
    })
    clearSearchResult()
    return navigateWithLocale({
      path: '/search-result',
      query: { ...route.query, labelId: name },
    }, {
      replace: true,
    })
  }

  function clearSearchResult() {
    searchState.value.sortType = ENUM_SORT_TYPE.RECOMMEND
    clearSearchData()
    clearFilter()
  }

  function clearSearchData() {
    searchState.value.resultList = []
    searchState.value.curPage = 1
    searchState.value.totalCount = 0
    searchState.value.finished = false
    searchState.value.loading = false
  }

  function clearFilter() {
    searchState.value.filter = {
      ...defaultFilter,
      priceMin: priceRange.value?.min ?? 0,
      priceMax: priceRange.value?.max ?? 1000,
    }
  }

  async function queryBrandList() {
    const res = await homePageController.homePageGetAllSearchBrandPost({})
    const brandList = res.data.value?.data || []
    searchState.value.brandList = brandList
  }

  async function querySubCategories() {
    if (!currentTab.value) {
      searchState.value.subCategories = []
      return
    }
    const res = await homePageController.homePageQrySubCategoryPost({
      labelId: currentTab.value,
    })
    searchState.value.subCategories = res.data.value?.data || []
  }

  async function queryPriceRanges() {
    const { data: result } = await attrController.attrListExperienceSearchFilterConfigPost({})
    searchState.value.priceRanges = result.value?.data?.priceRanges || []
  }

  function changeSortType(value: ENUM_SORT_TYPE) {
    trackEvent<SortApplyEvent>({
      event: ENUM_EventType.SortApply,
      applied_sort_id: value.toString(),
      applied_sort_name: sortTypeOptions.value.find(item => item.value === value)?.label ?? '',
    })
    searchState.value.sortType = value
    clearSearchData()
    querySearchResult()
  }

  function getCurrencyPrice(price?: number) {
    return getCurrencySymbol(userInfoStore.userInfo.currency) + toThousands(price ?? 0)
  }

  function clickCard(item: SearchDtoFormat, from: ENUM_CARD_FROM, clickPosition?: SelectItemClickPosition) {
    const city = item.cityNameEn?.toLowerCase() ?? 'x'
    const country = item.countryCode?.toLowerCase() ?? 'y'
    const title = encodeURIComponent(item.enTitle?.toLowerCase().split(' ').join('_').split('.').join('_') ?? 'title')
    trackData(item, from, clickPosition)
    if (item.totalCategory === E_RECOMMEND_CATEGORY.ATTR) {
      return navigateTo({
        path: `/attractions/detail/${country}/${city}/${title}.${locale.value}.html`,
        query: {
          ...getCommonQuery(),
          attrId: item.azgoId,
          destinationId: item.cityId,
          currency: userInfoStore.userInfo.currency,
        },
      }, {
        open: isMobile.value ? undefined : { target: '_blank' },
      })
    }
    if (item.totalCategory === E_RECOMMEND_CATEGORY.EXPERIENCE) {
      return navigateTo({
        path: `/experience/detail/${country}/${city}/${locale.value}.html`,
        query: {
          ...getCommonQuery(),
          serialNo: item.azgoId,
          destinationId: item.cityId,
          travelDate: dayjs().format('YYYY-MM-DD'),
          currency: userInfoStore.userInfo.currency,
        },
      }, {
        open: isMobile.value ? undefined : { target: '_blank' },
      })
    }
    if (!item.minPriceRecords?.[0].jumpUrlInfoDTO?.isBookDirect) {
      return clickSupplier(item, from, clickPosition, item.minPriceRecords?.[0], false)
    }
  }

  function trackData(item: SearchDtoFormat, from: ENUM_CARD_FROM, clickPosition?: SelectItemClickPosition, supplier?: CombineMinPriceRecordDTO, outerUrl?: string) {
    const baseParams = {
      city: item.cityName,
      city_id: item.cityId,
      country_code: item.countryCode,
    }
    let productDto: ProductDtoType
    if (item.totalCategory === E_RECOMMEND_CATEGORY.ATTR) {
      productDto = {
        attraction_id: item.azgoId ?? '',
        attraction_name: item.title ?? '',
        price: String(item.minPriceRecords?.[0]?.minPrice ?? 0),
        currency: userInfoStore.userInfo.currency,
      }
    }
    else if (item.totalCategory === E_RECOMMEND_CATEGORY.EXPERIENCE) {
      productDto = {
        experience_id: item.azgoId ?? '',
        experience_name: item.title ?? '',
        price: String(item.minPriceRecords?.[0]?.minPrice ?? 0),
        currency: userInfoStore.userInfo.currency,
      }
    }
    else {
      productDto = {
        product_id: item.azgoId ?? '',
        product_name: item.title ?? '',
        price: String(item.minPriceRecords?.[0]?.minPrice ?? 0),
        currency: userInfoStore.userInfo.currency,
      }
    }
    if (from === ENUM_CARD_FROM.RECENT_VIEWED) {
      trackEvent<RecentlyViewedClicksEvent>({
        event: ENUM_EventType.RecentlyViewedClicks,
        ...baseParams,
        ...productDto,
      })
    }
    else if (from === ENUM_CARD_FROM.HOME_RECOMMEND) {
      trackEvent<RecClicksEvent>({
        event: ENUM_EventType.RecClicks,
        ...baseParams,
        ...productDto,
      })
    }
    else if ([ENUM_CARD_FROM.SEARCH_RESULT, ENUM_CARD_FROM.RANK_PAGE].includes(from)) {
      trackEvent<SelectItemEvent>({
        event: ENUM_EventType.SelectItem,
        ...baseParams,
        ...productDto,
        list_position: item.listPosition ?? 0,
        click_position: clickPosition ?? 'blank_area',
        click_url: outerUrl,
        supplier_id: supplier?.supplierId ? String(supplier.supplierId) : '',
        supplier_name: supplier?.supplierName,
      })
    }
  }

  async function clickSupplier(item: SearchDtoFormat, from: ENUM_CARD_FROM, clickPosition?: SelectItemClickPosition, supplier?: CombineMinPriceRecordDTO, isTrack = true): Promise<any> {
    if (!supplier) {
      return
    }
    if (supplier.jumpUrlInfoDTO?.isBookDirect) {
      return clickCard(item, from)
    }
    let jumpUrl = ''
    jumpLoading.value = true
    jumpBrandUrl.value = supplier.jumpUrlInfoDTO?.jumpLogoUrl || ''
    if (item.totalCategory === E_RECOMMEND_CATEGORY.ATTR) {
      jumpUrl = await fetchAttrUrl(item, supplier)
    }
    else if (item.totalCategory === E_RECOMMEND_CATEGORY.EXPERIENCE) {
      jumpUrl = await fetchExperienceUrl(item, supplier)
    }
    else {
      jumpUrl = await fetchExperienceUrl(item, supplier)
    }

    if (isTrack) {
      trackData(item, ENUM_CARD_FROM.SEARCH_RESULT, clickPosition, supplier, jumpUrl)
    }

    setTimeout(() => {
      jumpBrandUrl.value = ''
      jumpLoading.value = false
    }, 3 * 1000)
    if (jumpUrl) {
      return navigateTo(jumpUrl, {
        external: true,
        open: isMobile.value
          ? undefined
          : {
              target: '_blank',
            },
      })
    }
    jumpBrandUrl.value = ''
    jumpLoading.value = false
    showToast(t('key.price.calendar.request.failed'))
  }

  async function fetchAttrUrl(item: SearchDTO, supplier: CombineMinPriceRecordDTO) {
    const result = await attrController.attrQueryVisitOutUrlPost({
      attrId: Number(item.azgoId),
      supplierId: supplier.supplierId,
      productId: supplier.jumpUrlInfoDTO?.skuId,
      price: supplier.minPrice,
      searchDate: dayjs().format('YYYY-MM-DD'),
      distCode: userInfoStore.userInfo.distCode,
      cid: route.query.cid as string,
      memberId: userInfoStore.userInfo.memberId,
      path: route.fullPath,
      productCode: supplier.jumpUrlInfoDTO?.productCode,
      productSkuCode: supplier.jumpUrlInfoDTO?.skuCode,
      currency: userInfoStore.userInfo.currency,
    })

    return result.data.value?.data?.jumpUrl || ''
  }

  async function fetchExperienceUrl(item: SearchDTO, supplier: CombineMinPriceRecordDTO) {
    const { data } = await attrRankController.rankGetExperiencesJumpUrlPost({
      supplierId: supplier.supplierId,
      path: supplier.jumpUrlInfoDTO?.pathUrl,
    })
    return data.value?.data?.data?.jumpUrl || ''
  }

  function addRecentViewdStore(azgoId: string, category: E_RECOMMEND_CATEGORY) {
    const maxLen = 8
    const recentViewd = recentViewdCookie.value || []
    const index = recentViewd.findIndex(item => item.azgoId === azgoId && item.category === category)
    if (index !== -1) {
      recentViewd.splice(index, 1)
    }
    recentViewd.unshift({ azgoId, category })
    recentViewdCookie.value = recentViewd.slice(0, maxLen)
  }

  async function queryRecentViewd() {
    const searchInfos = recentViewdCookie.value || []
    if (!searchInfos.length) {
      searchState.value.recentViewedList = []
      return
    }
    const res = await homePageController.homePageVisitHistoryPost({ searchInfos })
    searchState.value.recentViewedList = res.data.value?.data ?? []
  }

  async function searchHandler(keyword?: string, needToRank?: boolean) {
    trackEvent<SearchEvent>({
      event: ENUM_EventType.Search,
      search_term: keyword ?? '',
    })
    if (keyword) {
      handlerHistory(keyword)
    }
    if (needToRank && keyword) {
      const cityRes = await homePageController.homePageGetSearchTopCityPost({
        keyWord: keyword,
      })
      if (cityRes.data.value?.data?.success && cityRes.data.value?.data?.cityInfo?.cityId) {
        const cityInfo = cityRes.data.value.data.cityInfo
        return navigateWithLocale({
          path: '/rank',
          query: {
            ...getCommonQuery(),
            destinationId: cityInfo.cityId,
            destinationName: cityInfo.cityName,
            countryCode: cityInfo.countryCode,
          },
        })
      }
    }
    return navigateWithLocale({
      path: '/search-result',
      query: {
        ...getCommonQuery(),
        labelId: route.query.labelId ?? undefined,
        keyword,
      },
    })
  }

  function handlerSelectCity(item: RegionSearchResponse) {
    handlerHistory(item)
    return navigateWithLocale({
      path: '/rank',
      query: {
        ...getCommonQuery(),
        destinationId: item.cityId,
        destinationName: item.cityName,
        countryCode: item.countryCode,
      },
    })
  }

  function clickHistory(item: RegionSearchResponse | string) {
    if (typeof item === 'string') {
      return searchHandler(item)
    }
    return handlerSelectCity(item)
  }

  function handlerHistory(item: RegionSearchResponse | string) {
    const history = citySearchHistory.value || []
    const index = history.findIndex((city) => {
      if (typeof city === 'string' || typeof item === 'string') {
        return city === item
      }
      return city.cityId === item.cityId
    })
    if (index !== -1) {
      history.splice(index, 1)
    }
    history.unshift(item)
    citySearchHistory.value = history.slice(0, 8)
  }

  return {
    citySearchHistory,
    showFilterPopup,
    showSortByPopup,
    loading,
    finished,
    resultList,
    searchState,
    categories,
    subCategories,
    categoriesWithAll,
    currentTab,
    brandList,
    sortTypeOptions,
    sortType,
    filter,
    curPage,
    totalCount,
    priceRanges,
    priceRange,
    recentViewdCookie,
    recentViewedList,
    jumpLoading,
    jumpBrandUrl,
    clickHistory,
    handlerSelectCity,
    searchHandler,
    queryRecentViewd,
    addRecentViewdStore,
    queryPriceRanges,
    getCurrencyPrice,
    clickSupplier,
    clickCard,
    changeSortType,
    querySubCategories,
    queryBrandList,
    querySearchResult,
    queryCategories,
    scrollQuerySearchResult,
    changeTab,
    clearSearchResult,
    clearSearchData,
  }
}
